import React,{Suspense} from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import Loading from './pages/Loading';
import './assets/css/style.css'
import {Routes, Route} from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = React.lazy(() => import('./pages/Home'))
const About = React.lazy(() => import('./pages/About'))
const Providers = React.lazy(() => import('./pages/Providers'))
const Services = React.lazy(() => import('./pages/Services'))
const Contact = React.lazy(() => import('./pages/Contact'))

const PrimaryCare = React.lazy(() => import('./pages/PrimaryCare'))
const PrivacyPolicy = React.lazy(() => import('./pages/PrivacyPolicy'))
const TermsConditions = React.lazy(() => import('./pages/TermsConditions'))

function App() {
  return (
    <>
      <Suspense fallback={<Loading/>}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/providers" element={<Providers />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/primary-care" element={<PrimaryCare />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/terms-and-conditions" element={<TermsConditions />} />
          </Routes>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
          />
      </Suspense>
    </>
  );
}

export default App;
